const QueryString = class {
   
    constructor() {
        this.searchParams = new URLSearchParams();
    }
    set = function (key, value) {
        if(this.searchParams.has(key)) {
            this.searchParams.delete(key);
        }
        this.searchParams.append(key, value);
    }
    get = function (){
      return this.searchParams.toString()
    }
    remove = function(key) {
        this.searchParams.delete(key);
    }
}

export const query = new QueryString